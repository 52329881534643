<template>
  <div class="white-bg">
    <!-- header -->
    <div class="header-wrap">
      <div class="container">
        <div class="header">
          <div class="logo">
            <img src="../assets/images/northwell.png" alt="" />
          </div>
          <div class="btn-wrap">
            <button class="service-btn">
              <a href="#hospitalService">Hospitals & In-Patient Services</a>
            </button>
            <button class="service-btn">
              <a href="#urgentCare"> Urgent care</a>
            </button>
            <button class="begin-btn" @click="showEnroll">
              Enter Access Code
            </button>
          </div>

          <b-modal id="enroll" centered size="md" v-model="enrollShow">
            <template #modal-header>
              <div class="popUp-header">
                <b-button variant="close-btn" @click="closeEnroll">
                  <i class="fas fa-times"></i>
                </b-button>
              </div>
            </template>
            <b-container fluid>
              <div class="row" v-if="showAcessCode">
                <div class="col-md-12 enrollment-login-container">
                  <img src="../assets/images/lock.svg" />
                  <p>
                    Please Enter Your Access Code,<br />
                    to access site
                  </p>
                  <input
                    v-model="repAccessCode"
                    @input="removeRepErrorMessage()"
                    :class="{
                      'form-control is-invalid':
                        $v.repAccessCode.$error || repErrorFlag,
                    }"
                    class="custom-input"
                    placeholder="Enter Rep Access Code Eg: ABCD1212"
                  />
                  <div
                    class="form-group__message text-left ml-3 mt-2"
                    v-if="!$v.repAccessCode.required && $v.repAccessCode.$error"
                  >
                    Field is required
                  </div>
                  <div class="error_message mt-2" v-if="repErrorFlag === true">
                    Invalid Rep Access Code
                  </div>
                  <b-button @click="checkRepAccessCode" class="submit-btn mt-3"
                    >Submit Access Code</b-button
                  >
                </div>
              </div>

              <div class="row" v-if="!showAcessCode">
                <b-container>
                  <div class="col-md-12 enrollment-login-container">
                    <div
                      class="popUp-header conform-header"
                      style="border-bottom: 0px !important"
                    >
                      <div class="unlock-header">
                        <img src="../assets/images/unlock.svg" alt="unlock" />
                        <h4>
                          Please confirm that you are accessing the site with
                          the correct representative access code.
                        </h4>
                      </div>
                    </div>
                    <div class="popUp-details">
                      <div valign="top">
                        <b>{{ repInfo.firstName }} {{ repInfo.lastName }}</b>
                        <br />{{ repInfo.repCode }}<br />{{ repInfo.email
                        }}<br />{{ repInfo.phoneNumber }}
                      </div>
                      <div class="access-action-btn">
                        <input
                          type="submit"
                          @click="routeToRepEnroll"
                          name="submit_Agent"
                          id="submit_Agent"
                          value="Confirm"
                          class="btn btn-success mr-xs mb-sm mb-0"
                        />
                        <input
                          type="submit"
                          name="submit_Agent"
                          @click="showAcessCode = true"
                          id="submit_Agent"
                          value="Cancel"
                          class="btn btn-cancel mr-xs mb-sm mb-0"
                        />
                        <div></div>
                      </div>
                    </div>
                  </div>
                </b-container>
              </div>
            </b-container>
          </b-modal>
        </div>
      </div>
    </div>
    <!-- content  -->
    <div class="">
      <div class="banner-wrap">
        <img src="../assets/images/banner-img.png" alt="" />
      </div>
      <div class="banner-txt">
        <div class="container">
          <p class="tag-line">
            While you take care of business, we take care of you.
          </p>
        </div>
      </div>
      <div class="white-bg">
        <div class="container">
          <div class="row pt-5 pb-5 mt-5 mb-5">
            <div class="col-md-6">
              <h3 class="title">Why Northwell Direct?</h3>
              <p class="description">
                Northwell Direct, a Northwell Health company, utilizes an
                integrated healthcare management approach to help employers bend
                the cost curve, improve productivity and keep their employees
                healthy. Employers - big and small - can directly secure access
                to our quality provider network in the NY Metro area with
                recognized clinical expertise as well as a broad range of
                turnkey health services delivered on or near your worksite.
              </p>
            </div>
            <div class="col-md-6">
              <h3 class="title">
                One-stop shop for all employer healthcare needs:
              </h3>
              <ul class="comprehensive-ul">
                <li>Comprehensive services that optimize care for employees</li>
                <li>
                  Cost-containment solutions to help manage direct and indirect
                  health expenses
                </li>
                <li>
                  Optimal health plan design and services tailored to business
                  needs
                </li>
                <li>
                  Affordable and convenient options for employees and their
                  families
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="comprehensive">
        <div class="container">
          <h2 class="mb-4 pt-3">
            Comprehensive solutions for employers and employees
          </h2>
          <div class="row">
            <div class="col-md-6">
              <h3 class="title-head">Network</h3>
              <div class="comprehensive-item">
                <div class="network-logo">
                  <img src="../assets/images/network.png" />
                </div>
                <div class="network-list">
                  <ul>
                    <li>Self insured network platform</li>
                    <li>Over 27,000 providers</li>
                    <li>Integrated TPA</li>
                    <li>Transparent PBM</li>
                    <li>Powerful analytics and reporting</li>
                    <li>Customized care management programs</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <h3 class="title-head">At Work</h3>
              <div class="comprehensive-item">
                <div class="list-logo">
                  <img src="../assets/images/athome.png" />
                </div>
                <div class="at-work">
                  <ul>
                    <li>Flu and COVID vaccinations</li>
                    <li>On-site clinical services</li>
                    <li>Health access centers</li>
                    <li>In person and virtual behavioral health services</li>
                    <li>Occupational health screenings</li>
                    <li>Customized well-being programs</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="banner-txt mt-4">
        <div class="container">
          <h2 class="mb-0">
            Regional provider network: coverage you can count on
          </h2>
        </div>
      </div>
      <div class="map-wrap">
        <div class="map-content">
          <h3 class="title text-end comprehensive-title">
            One comprehensive network
          </h3>
          <div class="comp-service-wrap">
            <div class="comp-services">
              <div class="me-4">
                <div class="comprehensive-network">
                  <h2 class="total-num">54</h2>
                  <h3>Hospitals</h3>
                </div>
              </div>
              <div class="me-4">
                <div class="comprehensive-network">
                  <h2 class="total-num">7,500+</h2>
                  <h3>Primary Care Providers</h3>
                </div>
              </div>
            </div>
            <div class="comp-services">
              <div class="me-4">
                <div class="comprehensive-network">
                  <h2 class="total-num">152</h2>
                  <h3>Urgent Care Centers</h3>
                </div>
              </div>
              <div class="me-4">
                <div class="comprehensive-network">
                  <h2 class="total-num">27,000+</h2>
                  <h3 class="">Providers</h3>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="row">
            <div class="col-sm-0 col-md-0 col-xxl-2 pe-0"></div>
            <div class="col-sm-0 col-md-0 col-xxl-2 pe-0"></div>

            <div class="col-sm-6 col-md-3 col-xxl-2 pe-0">
              <div class="comprehensive-network">
                <h2 class="total-num">54</h2>
                <h3>Hospitals</h3>
              </div>
            </div>
            <div class="col-sm-6 col-md-3 col-xxl-2 pe-0">
              <div class="comprehensive-network">
                <h2 class="total-num">7,500+</h2>
                <h3>Primary Care Providers</h3>
              </div>
            </div>
            <div class="col-sm-6 col-md-3 col-xxl-2 pe-0">
              <div class="comprehensive-network">
                <h2 class="total-num">152</h2>
                <h3>Urgent Care Centers</h3>
              </div>
            </div>
            <div class="col-sm-6 col-md-3 col-xxl-2 pe-0">
              <div class="comprehensive-network">
                <h2 class="total-num">27,000+</h2>
                <h3 class="">Providers</h3>
              </div>
            </div>
          </div> -->
          <p class="text-center mt-4 mb-1">
            Network comprised of Hospitals, Urgent Cares, Ambulatory<br />
            Surgery Centers,Laboratories and Imaging Sites
          </p>
        </div>
      </div>
      <div class="container mt-4 pb-3">
        <h3 class="title">
          Backed by Northwell Health - a partner you can trust
        </h3>
        <p class="track-record">
          Excellent track record as both a large employer and a leading health
          care provider in the NY market.
        </p>
      </div>
      <div class="benefits text-center">
        <div class="container">
          <div class="row">
            <div class="col-md-4 mb-5">
              <div class="icon-wrap">
                <img src="../assets/images/state.png" />
              </div>
              <h3 class="benefit-txt">
                #1 Largest Private Employer in NY State
              </h3>
            </div>
            <div class="col-md-4 mb-5">
              <div class="icon-wrap">
                <img src="../assets/images/doctor.png" />
              </div>

              <h3 class="benefit-txt">12th Largest Health System Nationwide</h3>
            </div>
            <div class="col-md-4 mb-5">
              <div class="icon-wrap">
                <img src="../assets/images/patients.png" />
              </div>
              <h3 class="benefit-txt">2 Million+ Patients Treated Annually</h3>
            </div>
            <div class="col-md-4 mb-5">
              <div class="icon-wrap">
                <img src="../assets/images/employee.png" />
              </div>
              <h3 class="benefit-txt">85k Employees</h3>
            </div>
            <div class="col-md-4 mb-5">
              <div class="icon-wrap">
                <img src="../assets/images/hospitals.png" />
              </div>
              <h3 class="benefit-txt">
                54 Hospitals 700 Ambulatory Facilities
              </h3>
            </div>
            <div class="col-md-4 mb-5">
              <div class="icon-wrap">
                <img src="../assets/images/budget.png" />
              </div>
              <h3 class="benefit-txt">$12 Billion Annual Operating Budget</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Hospitals & In-Patient Services -->
    <div class="pdf-header" id="hospitalService">
      <div class="container">
        <div class="triangle"></div>
        <h2 class="mb-0">HOSPITAL AND IN-PATIENT PSYCHIATRIC Services</h2>
        <span class="sub-title">in the Northwell Direct Network</span>
      </div>
    </div>
    <div class="container pdf">
      <div >
        <div class="row">
          <div class="col-md-6">
            <div class="new-york">
              <h3 class="city-name mt-3">NEW YORK</h3>
              <div class="city">New York City</div>

              <div class="">
                <div class="city-places">Bronx</div>
                <ul>
                  <li>Montefiore Medical Center - Einstein Campus</li>
                  <li>Montefiore Medical Center - Moses Campus</li>
                  <li>Montefiore Medical Center - Wakefield Campus</li>
                  <li>Montefiore Medical Center - Westchester Square</li>
                  Campus<span class="s2">1</span>
                  <li>St. Barnabas Hospital</li>
                </ul>
              </div>
              <div class="">
                <div class="city-places">Brooklyn</div>
                <ul>
                  <li>Maimonides Medical Center</li>
                  <li>Wyckoff Heights Medical Center</li>
                </ul>
              </div>
              <div class="">
                <div class="city-places">Manhattan</div>
                <ul>
                  <li>
                    Lenox Health Greenwich Village<span class="s2">1</span>
                  </li>
                  <li>Lenox Hill Hospital</li>
                  <li>Lenox Hill Hospital Inpatient Psychiatric Services</li>
                  <li>Manhattan Eye, Ear & Throat Hospital (MEETH)</li>
                </ul>
              </div>
              <div class="">
                <div class="city-places">Queens</div>
                <ul>
                  <li>Long Island Jewish (LIJ) Forest Hills</li>
                  <li>Zucker Hillside Hospital<span class="s2">2</span></li>
                </ul>
              </div>
              <div class="">
                <div class="city-places">Staten Island</div>
                <ul>
                  <li>Staten Island University Hospital - North</li>
                  <li>
                    Staten Island University Hospital - South<span class="s2"
                      >2</span
                    >
                  </li>
                  <li>
                    Staten Island University Hospital - South Inpatient
                    Psychiatric Services
                  </li>
                </ul>
              </div>
              <div class="city">Long Island</div>
              <div class="">
                <div class="city-places">Nassau County</div>
                <ul>
                  <li>Glen Cove Hospital</li>
                  <li>Long Island Jewish (LIJ) Valley Stream</li>
                  <li>Long Island Jewish Medical Center</li>
                  <li>North Shore University Hospital</li>
                  <li>Plainview Hospita</li>
                  <li>Steven & Alexandra Cohen Children’s Medical Center</li>
                  <li>Syosset Hospital</li>
                  <li>Syosset Hospital Inpatient Psychiatric Services</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-6 mt-3">
            <div class="">
              <div class="city-places">Suffolk County</div>
              <ul>
                <li>Huntington Hospital</li>
                <li>Huntington Hospital Inpatient Psychiatric Services</li>
                <li>John T Mather Memorial Hospital</li>
                <li>
                  John T Mather Memorial Hospital Inpatient Psychiatric Services
                </li>
                <li>Peconic Bay Medical Center</li>
                <li>
                  South Oaks Hospital Inpatient Psychiatric Services<span
                    class="s2"
                    >2</span
                  >
                </li>
                <li>South Shore University Hospital</li>
              </ul>
            </div>
            <div class="city mt-3">Hudson Valley</div>
            <div class="">
              <div class="city-places">Orange County</div>
              <ul>
                <li>St. Luke’s Cornwall</li>
                <li>St. Luke’s Cornwall - Newburgh Campus</li>
              </ul>
            </div>
            <div class="">
              <div class="city-places">Rockland County</div>
              <ul>
                <li>Montefiore Nyack Hospital</li>
                <li>
                  Montefiore Nyack Hospital Inpatient Psychiatric Services
                </li>
              </ul>
            </div>
            <div class="">
              <div class="city-places">Westchester County</div>
              <ul>
                <li>Montefiore Mount Vernon</li>
                <li>Montefiore New Rochelle</li>
                <li>Northern Westchester Hospital</li>
                <li>
                  Northern Westchester Hospital Inpatient Psychiatric Services
                </li>
                <li>Phelps Memorial Hospital</li>
                <li>Phelps Memorial Hospital Inpatient Psychiatric Services</li>
                <li>White Plains Hospital Medical Center</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <h3 class="city-name mt-3">NEW Jersey</h3>
            <div class="">
              <div class="city-places">Essex County</div>
              <ul>
                <li>Clara Maass Medical Center</li>
                <li>
                  Clara Maass Medical Center Inpatient Psychiatric Services
                </li>
                <li>Cooperman Barnabas Medical Center</li>
                <li>Newark Beth Israel Medical Center</li>
                <li>
                  Newark Beth Israel Medical Center Inpatient Psychiatric
                  Services
                </li>
              </ul>
            </div>
            <div class="">
              <div class="city-places">Hudson County</div>
              <ul>
                <li>Jersey City Medical Center</li>
                <li>
                  Clara Maass Medical Center Inpatient Psychiatric Services
                </li>
                <li>
                  Jersey City Medical Center Inpatient Psychiatric Services
                </li>
              </ul>
            </div>
            <div class="">
              <div class="city-places">Mercer County</div>
              <ul>
                <li>Robert Wood Johnson University Hospital Hamilton</li>
              </ul>
            </div>
            <div class="">
              <div class="city-places">Middlesex County</div>
              <ul>
                <li>Robert Wood Johnson University Hospital New Brunswick</li>
              </ul>
            </div>
            <div class="">
              <div class="city-places">Ocean County</div>
              <ul>
                <li>Monmouth Medical Center</li>
                <li>Monmouth Medical Center Inpatient Psychiatric Services</li>
              </ul>
            </div>
            <div class="">
              <div class="city-places">Monmouth County</div>
              <ul>
                <li>Community Medical Center</li>
                <li>Monmouth Medical Center Southern Campus</li>
                <li>
                  Saint Barnabas Behavioral Health Center<span class="s2"
                    >2</span
                  >
                </li>
              </ul>
            </div>
            <div class="">
              <div class="city-places">Somerset County</div>
              <ul>
                <li>Robert Wood Johnson University Hospital Somerset</li>
                <li>
                  Robert Wood Johnson University Hospital Somerset Inpatient
                  Psychiatric Services
                </li>
              </ul>
            </div>
            <div class="">
              <div class="city-places">Union County</div>
              <ul>
                <li>Robert Wood Johnson University Hospital Rahway</li>
                <li>Trinitas Regional Medical Center</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="mb-5 mt-5">
          <span class="s2">1</span> Free Standing Emergency Dept & Oupatient
          Services only <br />
          <span class="s2">2</span> Behavioral Health/Substance Use services
        </div>
        <div class="footer-pd">
          <div class="nw-footer">
            <img src="@/assets/images/northwell-footer.png" alt="" />
          </div>
          <div class="right-details">
            For a searchable directory <br />of network providers, visit<br />
            <strong><a href="https://ciipa.northwell.edu/providers/northwell-direct-search/" target="_blank">Northwell.edu/NWDProviders</a></strong>
          </div>
        </div>
      </div>
    </div>

    <!-- urgent -->
    <div class="pdf-header pdf-urgent-health " id="urgentCare">
      <div class="triangle urgent-triangle"></div>
      <div class="urgent-head container">
        <h2 class="mb-0">URGENT CARE CENTERS</h2>
        <span class="sub-title ms-2">in the Northwell Direct Network</span>
      </div>
    </div>
    <div class="container pdf" >
      <div class="row">
        <div class="col-md-6">
          <div class="new-york">
            <h3 class="city-name urgent-health mt-3">NEW YORK</h3>
            <div class="city city-urgent-health">New York City</div>

            <div class="">
              <div class="city-places">Bronx</div>
              <ul>
                <li>PM Pediatrics Broadway</li>
                <li>ProHealth Urgent Care Morris Park Avenue</li>
              </ul>
            </div>
            <div class="">
              <div class="city-places">Brooklyn</div>
              <ul>
                <li>GoHealth Urgent Care Kent Avenue</li>
                <li>GoHealth Urgent Care Flatbush Avenue</li>
                <li>PM Pediatrics Coney Island Avenue</li>
                <li>PM Pediatrics 86th Street</li>
                <li>PM Pediatrics Atlantic Avenue</li>
                <li>ProHealth Urgent Care Coney Island Avenue</li>
                <li>ProHealth Urgent Care Court Street</li>
                <li>ProHealth Urgent Care Kings Highway</li>
                <li>ProHealth Urgent Care Nostrand Avenue</li>
                <li>ProHealth Urgent Care Avenue U</li>
                <li>ProHealth Urgent Care 18th Avenue</li>
                <li>ProHealth Urgent Care Manhattan Avenue</li>
              </ul>
            </div>
            <div class="">
              <div class="city-places">Manhattan</div>
              <ul>
                <li>GoHealth Urgent Care Chelsea</li>
                <li>GoHealth Urgent Care East 81st</li>
                <li>GoHealth Urgent Care East 96th</li>
                <li>GoHealth Urgent Care Greenwich Village</li>
                <li>GoHealth Urgent Care West 69th</li>
                <li>GoHealth Urgent Care West 100th</li>
                <li>GoHealth Urgent Care West 103rd</li>
                <li>ProHealth Urgent Care Gramercy Park</li>
              </ul>
            </div>
            <div class="">
              <div class="city-places">Queens</div>
              <ul>
                <li>GoHealth Urgent Care Astoria</li>
                <li>GoHealth Urgent Care Bayside</li>
                <li>GoHealth Urgent Care Corona</li>
                <li>GoHealth Urgent Care Forest Hills</li>
                <li>GoHealth Urgent Care Ridgewood</li>
                <li>GoHealth Urgent Care Springfield Gardens</li>
                <li>PM Pediatrics Bayside</li>
                <li>PM Pediatrics Forest Hills</li>
                <li>ProHealth Urgent Care College Point</li>
                <li>ProHealth Urgent Care Glen Oaks</li>
                <li>ProHealth Urgent Care Howard Beach</li>
                <li>ProHealth Urgent Care Little Neck</li>
              </ul>
            </div>
            <div class="">
              <div class="city-places">Staten Island</div>
              <ul>
                <li>GoHealth Urgent Care Bulls Head</li>
                <li>GoHealth Urgent Care Dongan Hills</li>
                <li>GoHealth Urgent Care Eltingville</li>
                <li>GoHealth Urgent Care Great Kills</li>
                <li>GoHealth Urgent Care New Dorp</li>
                <li>PM Pediatrics Richmond Avenue</li>
                <li>ProHealth Urgent Care Amboy Road</li>
                <li>ProHealth Urgent Care Arden Avenue</li>
                <li>ProHealth Urgent Care Richmond Avenue</li>
                <li>ProHealth Urgent Care Victory Blvd</li>
              </ul>
            </div>
            <div class="city city-urgent-health">Long Island</div>
            <div class="">
              <div class="city-places">Nassau County</div>
              <ul>
                <li>GoHealth Urgent Care Bellmore</li>
                <li>GoHealth Urgent Care Glen Cove</li>
                <li>GoHealth Urgent Care Great Neck</li>
                <li>GoHealth Urgent Care Greenvale</li>
                <li>GoHealth Urgent Care Hewlett</li>
                <li>GoHealth Urgent Care Levittown</li>
                <li>GoHealth Urgent Care Lynbrook</li>
                <li>GoHealth Urgent Care Massapequa Park</li>
                <li>GoHealth Urgent Care Mineola</li>
                <li>GoHealth Urgent Care Oceanside</li>
                <li>GoHealth Urgent Care Plainview</li>
                <li>GoHealth Urgent Care Port Washington</li>
                <li>GoHealth Urgent Care Rockville Centre</li>
                <li>GoHealth Urgent Care Roslyn</li>
                <li>GoHealth Urgent Care Syosset</li>
                <li>GoHealth Urgent Care West Hempstead</li>
                <li>PM Pediatrics Bellerose</li>
                <li>PM Pediatrics Carle Place</li>
                <li>PM Pediatrics Manhasset</li>
                <li>• PM Pediatrics Massapequa Park</li>
                <li>PM Pediatrics Rockville Centre</li>
                <li>PM Pediatrics Syosset</li>
                <li>ProHealth Urgent Care Freeport</li>
                <li>ProHealth Urgent Care Jericho</li>
                <li>ProHealth Urgent Care Long Beach</li>
                <li>ProHealth Urgent Care Massapequa Park</li>
                <li>ProHealth Urgent Care Plainview</li>
                <li>ProHealth Urgent Care Roslyn Heights</li>
                <li>ProHealth Urgent Care Wantagh</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-6 mt-3">
          <div class="">
            <div class="city-places">Suffolk County</div>
            <ul>
              <li>GoHealth Urgent Care Amityville</li>
              <li>GoHealth Urgent Care Bay Shore</li>
              <li>GoHealth Urgent Care Bohemia</li>
              <li>GoHealth Urgent Care Bridgehampton</li>
              <li>GoHealth Urgent Care Commack</li>
              <li>GoHealth Urgent Care East Northport (Pediatrics)</li>
              <li>GoHealth Urgent Care Elwood</li>
              <li>GoHealth Urgent Care Hampton Bays</li>
              <li>GoHealth Urgent Care Huntington</li>
              <li>GoHealth Urgent Care Huntington Station</li>
              <li>GoHealth Urgent Care Lake Grove</li>
              <li>GoHealth Urgent Care Port Jefferson Station</li>
              <li>GoHealth Urgent Care Riverhead</li>
              <li>GoHealth Urgent Care Smithtown</li>
              <li>GoHealth Urgent Care West Islip</li>
              <li>PM Pediatrics Commack</li>
              <li>PM Pediatrics North Babylon</li>
              <li>PM Pediatrics Selden</li>
              <li>ProHealth Urgent Care Amityville</li>
              <li>ProHealth Urgent Care Huntington</li>
              <li>ProHealth Urgent Care Huntington Station</li>
              <li>ProHealth Urgent Care West Islip</li>
            </ul>
          </div>
          <div class="city city-urgent-health mt-3">Hudson Valley</div>
          <div class="">
            <div class="city-places">Dutchess County</div>
            <ul>
              <li>PM Pediatrics Hopewell Junction</li>
            </ul>
          </div>
          <div class="">
            <div class="city-places">Rockland County</div>
            <ul>
              <li>PM Pediatrics Spring Valley Marketplace</li>
            </ul>
          </div>
          <div class="">
            <div class="city-places">Westchester County</div>
            <ul>
              <li>GoHealth Urgent Care Dobbs Ferry</li>
              <li>GoHealth Urgent Care New Rochelle</li>
              <li>GoHealth Urgent Care Tarrytown</li>
              <li>GoHealth Urgent Care Yorktown Heights</li>
              <li>PM Pediatrics Mamaroneck</li>
              <li>Phelps Memorial Hospital Inpatient Psychiatric Services</li>
              <li>PM Pediatrics Yonkers</li>
            </ul>
          </div>
          <div class="city urgent-health">NEW JERSEY</div>
          <div class="">
            <div class="city-places">Bergen County</div>
            <ul>
              <li>PM Pediatrics Paramus</li>
            </ul>
          </div>
          <div class="">
            <div class="city-places">Camden County</div>
            <ul>
              <li>PM Pediatrics Cherry Hill</li>
            </ul>
          </div>
          <div class="">
            <div class="city-places">Essex County</div>
            <ul>
              <li>PM Pediatrics Livingston</li>
            </ul>
          </div>
          <div class="">
            <div class="city-places">Gloucester County</div>
            <ul>
              <li>PM Pediatrics Turnersville</li>
              <li>Clara Maass Medical Center Inpatient Psychiatric Services</li>
              <li>Jersey City Medical Center Inpatient Psychiatric Services</li>
            </ul>
          </div>

          <div class="">
            <div class="city-places">Middlesex County</div>
            <ul>
              <li>PM Pediatrics North Brunswick</li>
            </ul>
          </div>
          <div class="">
            <div class="city-places">Monmouth County</div>
            <ul>
              <li>PM Pediatrics Holmdel</li>
            </ul>
          </div>
          <div class="">
            <div class="city-places">Morris County</div>
            <ul>
              <li>PM Pediatrics Pompton Plains</li>
            </ul>
          </div>
          <div class="">
            <div class="city-places">Passaic County</div>
            <ul>
              <li>PM Pediatrics Clifton</li>
            </ul>
          </div>
          <div class="">
            <div class="city-places">Somerset County</div>
            <ul>
              <li>Robert Wood Johnson University Hospital Somerset</li>
              <li>PM Pediatrics Bridgewater</li>
            </ul>
          </div>
          <div class="">
            <div class="city-places">Union County</div>
            <ul>
              <li>PM Pediatrics Springfield</li>
            </ul>
          </div>
          <div class="city urgent-health">CONNECTICUT</div>
          <div class="">
            <div class="city-places">Hartford County</div>
            <ul>
              <li>PM Pediatrics Manchester</li>
              <li>PM Pediatrics West Hartford/li></li>
              <li>
                Saint Barnabas Behavioral Health Center<span class="s2">2</span>
              </li>
            </ul>
          </div>
          <div class="city urgent-health">PENNSYLVANIA</div>
          <div class="">
            <div class="city-places">Delaware County</div>
            <ul>
              <li>PM Pediatrics Springfield</li>
              <li>PM Pediatrics Wayne</li>
            </ul>
          </div>
          <div class="">
            <div class="city-places">Montgomery County</div>
            <ul>
              <li>PM Pediatrics Abington</li>
              <li>PM Pediatrics Wynnewood</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="footer-pd">
        <div class="nw-footer">
          <img src="@/assets/images/northwell-footer.png" alt="" />
        </div>
        <div class="right-details urgent-right-details">
          For a searchable directory <br />of network providers, visit<br />
          <strong><a href="https://ciipa.northwell.edu/providers/northwell-direct-search/" target="_blank">Northwell.edu/NWDProviders</a></strong>
        </div>
      </div>
    </div>
    <!--footer section -->
    <div class="access-footer">
      <div class="core-logo logo-footer">
        <img src="../assets/images/core-logo.png" />
      </div>
      <div class="access-menu home-footer" style="margin: auto">
        <ul class="access-ul home-ul">
          <li>
            <a :href="menuLink.routeToGroupRegistration" target="_blank"
              ><span> GROUP APPLICATION</span></a
            >
          </li>
          <li v-b-modal.employeeEnrollment-pop @click="resetEnrollGroup()">
            <a> EMPLOYEE ENROLLMENT</a>
          </li>
          <li>
            <a :href="menuLink.routeToRepRegistration" target="_blank"
              ><span> REP REGISTRATION</span></a
            >
          </li>
          <li>
            <a :href="menuLink.routeToRepDashboard" target="_blank"
              ><span> REP DASHBOARD</span></a
            >
          </li>
          <li>
            <a :href="menuLink.routeToMemberDashboard" target="_blank"
              ><span> MEMBER DASHBOARD</span></a
            >
          </li>
          <li @click="routeToNotMySITE" style="cursor: pointer">NOT MY SITE</li>
        </ul>
      </div>
      <div class="copyright">
        <p>Copyright &copy; {{ currentYear }}</p>
        <p class="copyright-left"></p>
        <p>All Rights Reseved</p>
      </div>
    </div>

    <!-- Employee Enrollment pop up -->
    <b-modal
      id="employeeEnrollment-pop"
      centered
      size="md"
      v-model="enrollmentShow"
    >
      <template #modal-header>
        <div class="popUp-header">
          <h4>Employee Enrollment</h4>
          <b-button variant="close-btn" @click="enrollmentShow = false">
            <i class="fas fa-times"></i>
          </b-button>
        </div>
      </template>
      <b-container fluid>
        <div v-if="!showProceedConformation">
          <div class="form-group mb-3">
            <label>Group Enrollment Code</label>
            <input
              type="text"
              v-model="groupEnrollmentCode"
              @input="removeGroupErrorMessage()"
              :class="{
                'form-control is-invalid':
                  $v.groupEnrollmentCode.$error || groupErrorFlag,
              }"
              class="custom-input"
              placeholder="Enter Group Enrollment CodeEg: ABCD1212"
            />
            <div
              class="form-group__message text-left ml-3 mt-2"
              v-if="
                !$v.groupEnrollmentCode.required &&
                $v.groupEnrollmentCode.$error
              "
            >
              Field is required
            </div>
            <div class="error_message mt-2" v-if="groupErrorFlag === true">
              Invalid Group Enrollment Code
            </div>
          </div>
          <div class="form-group semiText">
            <p>
              If you don't know your group enrollment code please contact your
              company's human resource department.
            </p>
          </div>
          <div class="form-group">
            <button class="univ-btn" @click="checkGroupEnrollmentCode()">
              Continue
            </button>
          </div>
        </div>
        <div v-if="showProceedConformation">
          <div class="text-left mb-3">
            <div class="mt-2">
              The company that you will be enrolling under is:<strong
                style="color: #28a745"
              >
                {{ groupInfo.name }}</strong
              >
            </div>
            <div
              class="mt-2 group-not-employer-container"
              @click="backToEnrollmentCode"
            >
              <p>
                If this is NOT the your employer please click here, to re-enter
                your employer enrollment code.
              </p>
              <p>
                If you have questions please contact your Employer for correct
                code.
              </p>
            </div>
          </div>
          <b-row>
            <b-col cols="6" sm="6" md="6" lg="6" xl="6">
              <div class="form-group">
                <button class="univ-btn" @click="routeToGroupEnroll">
                  Yes
                </button>
              </div>
            </b-col>
            <b-col cols="6" sm="6" md="6" lg="6" xl="6">
              <button class="univ-btn" @click="resetEnrollGroup">No</button>
            </b-col>
          </b-row>
        </div>
      </b-container>
    </b-modal>
    <location-permission />
  </div>
</template>
<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";
import store from "../store";
import { MUTATION_SHOW_LOADING_SPINNER } from "../store";
import LocationPermission from "../components/LocationPermission.vue";
export default {
  name: "NewHome",
  components: {
    LocationPermission,
  },
  mounted() {
    let app = this;
    store.commit(MUTATION_SHOW_LOADING_SPINNER, false);
    app.$store.dispatch("isEmployeeEnrollment", false);
    app.checkLandingUrl();
    app.getGroupHeaderInfo();
    app.getAgentHeaderInfo();
  },
  data() {
    return {
      enrollShow: false,
      showAcessCode: true,
      repAccessCode: null,
      repRouteName: null,
      repErrorFlag: false,
      repInfo: {
        firstName: null,
        lastName: null,
        email: null,
        repCode: null,
        phoneNumber: null,
        logo: null,
        image: null,
      },
      logo: null,
      image: null,
      phoneNumber: null,
      email: null,
      repName: null,
      groupName: null,
      agentInfo: null,
      showProceedConformation: false,
      enrollmentShow: false,
      groupEnrollmentCode: null,
      groupErrorFlag: false,
      groupInfo: {
        name: "",
      },
      groupEmployeeInfo: null,
      menuLink: {
        routeToRepDashboard: process.env.VUE_APP_REP_DASHBOARD,
        routeToMemberDashboard: process.env.VUE_APP_MEMBER_DASHBOARD,
        routeToRepRegistration: process.env.VUE_APP_REP_REGISTRATION,
        routeToGroupRegistration: process.env.VUE_APP_GROUP_REGISTRATION,
      },
    };
  },
  validations: {
    repAccessCode: {
      required,
    },
    groupEnrollmentCode: {
      required,
    },
  },
  computed: {
    landingInfo() {
      return this.$store.getters.getLandingInfo;
    },
    landingUrl() {
      return this.$store.getters.getLandingUrl;
    },
    currentYear() {
      let currentDate = new Date();
      let currentYear = currentDate.getFullYear();
      return currentYear
    }
  },
  methods: {
    showEnroll() {
      this.enrollShow = true;
    },
    closeEnroll() {
      this.enrollShow = false;
    },
    checkRepAccessCode() {
      let app = this;
      app.$v.repAccessCode.$touch();
      if (app.$v.repAccessCode.$invalid) {
        return false;
      } else {
        axios.defaults.headers.common["Authorization"] =
          "Basic " + process.env.VUE_APP_CLIENT_API_TOKEN;
        let app = this;
        axios
          .get(process.env.VUE_APP_API_BASE + "/validate-agent", {
            params: {
              agent_code: app.repAccessCode,
            },
          })
          .then(function (response) {
            if (response.data.status == "success") {
              app.repInfo.firstName = response.data.data.first_name;
              app.repInfo.lastName = response.data.data.last_name;
              app.repInfo.email = response.data.data.email;
              app.repInfo.phoneNumber = response.data.data.phone;
              app.repInfo.repCode = response.data.data.agent_code;
              app.repInfo.logo = response.data.data.logo;
              app.repInfo.image = response.data.data.image;
              app.showRepInfo();
              /*  if (app.landingInfo.type !== 'agent') {
                    app.agentInfo = response.data.data
                  }*/
            }
            if (response.status == 204) {
              app.repErrorFlag = true;
              app.showRepConformation = false;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    routeToRepEnroll() {
      console.log("inside route to rep");
      let app = this;
      axios.defaults.headers.common["Authorization"] =
        "Basic " + process.env.VUE_APP_CLIENT_API_TOKEN;
      axios
        .get(process.env.VUE_APP_API_BASE + "/validate-agent", {
          params: {
            agent_code: app.repAccessCode,
          },
        })
        .then(function (response) {
          if (response.data.status == "success") {
            store.commit(MUTATION_SHOW_LOADING_SPINNER, true);
            app.$store.dispatch("isRepAuthenticated", true);
            let repInfo = response.data.data;
            app.repRouteName = repInfo.landing_url;
            let enrollType = "Agent";
            let redirectionUrl = "accessPage";
            if (repInfo.landing_url == null || repInfo.landing_url == "") {
              app.$router.push(
                "/landing-config-error/" + enrollType + "/" + redirectionUrl
              );
            } else {
              // app.$router.push(`/${app.repRouteName}`)
              if (!app.landingUrl) {
                store.dispatch("landingUrl", app.repRouteName);
              }
              if (app.landingInfo) {
                if (app.landingInfo.type !== "agent") {
                  app.$store.dispatch("repInfo", app.agentInfo);
                }
                if (app.landingUrl && app.landingInfo.type === "agent") {
                  store.dispatch(
                    "landingUrl",
                    encodeURIComponent(app.repRouteName)
                  );
                }
              }
              app.$router.go();
            }
            store.commit(MUTATION_SHOW_LOADING_SPINNER, false);
          }
          if (response.status == 204) {
            app.repErrorFlag = true;
            app.showRepConformation = false;
          }
        })
        .catch(function (error) {
          store.commit(MUTATION_SHOW_LOADING_SPINNER, false);
          console.log(error);
        });
    },
    showRepInfo() {
      let app = this;
      app.showAcessCode = false;
    },
    removeRepErrorMessage() {
      let app = this;
      app.repErrorFlag = false;
    },
    getGroupHeaderInfo() {
      let app = this;
      if (app.landingInfo) {
        if (app.landingInfo.type !== "agent") {
          console.log("inside get group header info");
          axios.defaults.headers.common["Authorization"] =
            "Basic " + process.env.VUE_APP_CLIENT_API_TOKEN;
          axios
            .get(
              process.env.VUE_APP_API_BASE + "/get-group-landing-page-info",
              {
                params: {
                  group_id: app.landingInfo.group_id,
                },
              }
            )
            .then(function (response) {
              app.clearLandingInfo();
              if (response.data.data.image !== null) {
                app.logo = response.data.data.image;
              }
              app.email = response.data.data.display_email;
              app.phoneNumber = response.data.data.display_phone;
              app.groupName = response.data.data.display_name;
              if (response.data.data.logo !== null) {
                app.image = response.data.data.logo;
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      }
    },
    getAgentHeaderInfo() {
      let app = this;
      if (app.landingInfo) {
        if (app.landingInfo.type !== "group") {
          console.log("inside get agent header info");
          app.landing = {};
          axios.defaults.headers.common["Authorization"] =
            "Basic " + process.env.VUE_APP_CLIENT_API_TOKEN;
          axios
            .get(
              process.env.VUE_APP_API_BASE + "/get-agent-landing-page-info",
              {
                params: {
                  agent_id: app.landingInfo.agent_id,
                },
              }
            )
            .then((response) => {
              app.clearLandingInfo();
              if (response.data.data.logo !== null) {
                app.logo = response.data.data.logo;
              }
              app.email = response.data.data.display_email;
              app.phoneNumber = response.data.data.display_phone;
              app.repName = response.data.data.display_name;
              if (response.data.data.image !== null) {
                app.image = response.data.data.image;
              }
              console.log("inside get agent header info", app.logo, app.email);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    },
    clearLandingInfo() {
      let app = this;
      (app.logo = null),
        (app.image = null),
        (app.phoneNumber = null),
        (app.email = null),
        (app.repName = null),
        (app.groupName = null);
    },
    resetEnrollGroup() {
      let app = this;
      app.showProceedConformation = false;
      app.enrollmentShow = false;
      app.groupEnrollmentCode = null;
      app.groupErrorFlag = false;
      app.$v.$reset();
    },
    checkGroupEnrollmentCode() {
      let app = this;
      app.$v.groupEnrollmentCode.$touch();
      if (app.$v.groupEnrollmentCode.$invalid) {
        return false;
      } else {
        axios.defaults.headers.common["Authorization"] =
          "Basic " + process.env.VUE_APP_CLIENT_API_TOKEN;
        axios
          .get(process.env.VUE_APP_API_BASE + "/validate-group", {
            params: {
              group_code: app.groupEnrollmentCode,
            },
          })
          .then(function (response) {
            if (response.data.status == "success") {
              app.groupInfo.name = response.data.data.name;
              app.showProceedConformation = true;
              app.fetchGroupInformation(response.data.data.group_id);
            }
            if (response.status == 204) {
              app.groupErrorFlag = true;
              app.showProceedConformation = false;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    fetchGroupInformation(groupId) {
      let app = this;
      axios.defaults.headers.common["Authorization"] =
        "Basic " + process.env.VUE_APP_CLIENT_API_TOKEN;
      axios
        .get(process.env.VUE_APP_API_BASE + "/get-group-landing-page-info", {
          params: {
            group_id: groupId,
          },
        })
        .then(function (response) {
          app.groupEmployeeInfo = response.data.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    routeToGroupEnroll() {
      let app = this;
      axios.defaults.headers.common["Authorization"] =
        "Basic " + process.env.VUE_APP_CLIENT_API_TOKEN;
      axios
        .get(process.env.VUE_APP_API_BASE + "/validate-group", {
          params: {
            group_code: app.groupEnrollmentCode,
          },
        })
        .then(function (response) {
          if (response.data.status == "success") {
            store.commit(MUTATION_SHOW_LOADING_SPINNER, true);
            app.$store.dispatch("isEmployeeEnrollment", true);
            let responseData = response.data.data;
            app.groupRouteName = responseData.landing_url;
            app.enrollmentShow = false;
            app.$store.dispatch("isRepAuthenticated", true);
            let enrollType = "Group";
            let redirectionUrl = "accessPage";
            if (
              responseData.landing_url == null ||
              responseData.landing_url == ""
            ) {
              app.$router.push(
                "/landing-config-error/" + enrollType + "/" + redirectionUrl
              );
            } else {
              app.$router.push({ name: "main" });
              app.$store.dispatch("landingInfo", null);
              app.$store.dispatch("repInfo", null);
              app.$store.dispatch("groupInfo", null);
              app.$store.dispatch(
                "landingUrl",
                encodeURIComponent(app.groupRouteName)
              );
              // app.$store.dispatch('groupInfo', app.groupEmployeeInfo)
              app.$router.go();
            }
            store.commit(MUTATION_SHOW_LOADING_SPINNER, true);
          }
          if (response.status == 204) {
            app.groupErrorFlag = true;
            app.showProceedConformation = false;
          }
        })
        .catch(function (error) {
          store.commit(MUTATION_SHOW_LOADING_SPINNER, false);
          console.log(error);
        });
    },
    removeGroupErrorMessage() {
      let app = this;
      app.groupErrorFlag = false;
      app.repErrorFlag = false;
    },
    backToEnrollmentCode() {
      let app = this;
      app.showProceedConformation = false;
      app.groupEnrollmentCode = null;
      app.$v.$reset();
    },
    routeToNotMySITE() {
      let app = this;
      localStorage.clear();
      app.$router.push("/");
      app.$router.go();
      app.$store.dispatch("isRepAuthenticated", false);
      app.$store.dispatch("landingInfo", null);
      app.$store.dispatch("landingUrl", null);
      app.$store.dispatch("repInfo", null);
      app.$store.dispatch("groupInfo", null);
    },
    checkLandingUrl() {
      let app = this;
      if (
        app.landingUrl === "landing-config-error/Group/accessPage" &&
        !app.$store.getters.isRepAuthenticated
      ) {
        app.$store.dispatch("landingUrl", null);
      }
    },
  },
  watch: {
    landingInfo: function () {
      let app = this;
      let landingParams = window.location.pathname.slice(1);
      if (landingParams !== app.$store.getters.getLandingUrl) {
        if (app.landingInfo.type === "group") {
          app.getGroupHeaderInfo();
        }
        if (app.landingInfo.type === "agent") {
          app.getAgentHeaderInfo();
        }
      }
    },
  },
};
</script>
<style src="../assets/css/new-landing.css">
</style>
